


































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Recipe,
  Produce,
  ResolvedIngredient,
  ResolvedIngredientSection,
  IngredientSection,
} from "../services/RecipeRepository";

@Component({
  filters: {
    formatNumber: function (n: number): string {
      return new Intl.NumberFormat("de-CH", {
        maximumFractionDigits: 2,
      }).format(n);
    },
  },
})
export default class RecipeDetail extends Vue {
  private recipe: Recipe | null = null;
  private sections: Array<ResolvedIngredientSection> = [];
  private amountMultiplier = 1;
  private portions = 1;
  private showCustomAmounts: Record<number, boolean> = {};
  private customAmounts: Record<number, number> = {};

  async created() {
    const slug = this.$route.params.slug;
    await Promise.all([
      this.$store.dispatch("loadProduce"),
      this.$store.dispatch("loadRecipe", slug),
    ]);

    this.recipe = this.$store.getters.recipe(slug);
  }

  getTotal(field: keyof Produce) {
    return this.recipe?.ingredients.reduce(
      (cur, section: IngredientSection, sectionId) => {
        return (
          section.ingredients.reduce(
            (cur: number, ing: ResolvedIngredient, index) => {
              if (typeof ing.amount === "undefined") {
                return cur;
              }

              const produce = ing.produce;
              const customomAmountId = this.toId(sectionId, index);

              if (typeof this.customAmounts[customomAmountId] !== "undefined") {
                return (
                  ((produce[field] as number) *
                    this.customAmounts[customomAmountId]) /
                    produce.baseAmount +
                  cur
                );
              }

              const amountPerUnit = produce[field] as number;

              return amountPerUnit * ing.amount * this.amountMultiplier + cur;
            },
            0
          ) + cur
        );
      },
      0
    );
  }

  get totalProtein(): number {
    return this.getTotal("protein") ?? 0;
  }

  get totalFat(): number {
    return this.getTotal("fat") ?? 0;
  }

  get totalCarbs(): number {
    return this.getTotal("carbs") ?? 0;
  }

  private showCustomAmount(i: number) {
    this.$set(this.showCustomAmounts, i, true);
  }

  private hideCustomAmount(i: number) {
    this.$set(this.customAmounts, i, undefined);
    this.$set(this.showCustomAmounts, i, false);
  }

  private toId(section: number, i: number) {
    return section * 100 + i;
  }
}
