import { Produce, Recipe, RecipeSummary } from "./services/RecipeRepository";

export default {
    state: {
        produce: [],
        recipeSummaries: [],
        recipes: {}
    },
    getters: {
        allRecipes: (state: any) => {
            return state.recipeSummaries;
        },
        tag: (state: any) => (tag: string) => {
            return state.recipeSummaries.filter((summary: Recipe) => summary.tags.indexOf(tag) !== -1);
        },
        recipe: (state: any) => (slug: string) => {
            return state.recipes[slug];
        },
        allProduce: (state: any) => {
            return state.produce;
        }
    },
    mutations: {
        produce(state: any, produce: Produce[]) {
            state.produce = produce;
        },
        recipeSummaries(state: any, recipeSummaries: RecipeSummary[]) {
            state.recipeSummaries = recipeSummaries;
        },
        recipe(state: any, recipe: Recipe) {
            state.recipes[recipe.slug] = recipe;
        },
    },
    actions: {
        async loadProduce(context: any) {
            const response = await fetch('/api/produce');

            const data = await response.json();

            context.commit('produce', data);
        },
        async loadRecipes(context: any) {
            const response = await fetch('/api/recipe');

            const data = await response.json();

            context.commit('recipeSummaries', data);
        },
        async loadRecipe(context: any, slug: string) {
            const response = await fetch(`/api/recipe/${slug}`);

            const data = await response.json();

            context.commit('recipe', data);
        },
    }
};
