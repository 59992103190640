import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n'
import Vuex from "vuex";
import store from './store';

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(Vuex);

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {}
  }
});

new Vue({
  i18n,
  store: new Vuex.Store(store),
  render: h => h(App),
}).$mount('#app')
