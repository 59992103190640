












import { Component, Vue } from "vue-property-decorator";
import { RecipeSummary } from "@/services/RecipeRepository";

@Component
export default class Recipes extends Vue {
  async created() {
    await this.$store.dispatch("loadRecipes");
  }

  get recipes(): RecipeSummary[] {
    return this.$store.getters.allRecipes;
  }

  get recipesLoaded(): boolean {
    return this.$store.getters.recipesLoaded;
  }
}
