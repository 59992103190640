





























import { Component, Mixins } from "vue-property-decorator";
import VueRouter from "vue-router";
import Recipes from "./components/Recipes.vue";
import Tag from "./components/Tag.vue";
import RecipeDetail from "./components/RecipeDetail.vue";
import AppUpdate from "./mixins/AppUpdate";

const routes = [
  { name: "home", path: "/recipe", component: Recipes },
  { path: "/recipe/:slug", component: RecipeDetail },
  { path: "/tag/:tag", component: Tag },
];

const router = new VueRouter({ routes });

@Component({
  router,
  components: {
    Recipes,
    Tag,
  },
  methods: {
    setLocale(locale: string) {
      this.$root.$i18n.locale = locale;
    },
  },
})
export default class App extends Mixins(AppUpdate) {}
