import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppUpdate extends Vue {
    private appUpdateAvailable = false;
    private registration: ServiceWorkerRegistration | undefined;
    private refreshing = false;

    created() {
        document.addEventListener('swUpdated', this.updateAvailable as EventListener, { once: true });

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) {
                return;
            }

            this.refreshing = true;

            window.location.reload();
        })
    }

    updateAvailable(event: CustomEvent) {
        this.registration = event.detail;

        this.appUpdateAvailable = true;
    }

    refreshApp() {
        this.appUpdateAvailable = false;

        if (!this.registration || !this.registration.waiting) {
            return;
        }

        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
}
