













import { Component, Vue } from "vue-property-decorator";

@Component
export default class Tag extends Vue {
  private tag: string | null = null;

  async created() {
    this.tag = this.$route.params.tag;
    if (typeof this.tag === "undefined") {
      this.$router.push("home");

      return;
    }

    await this.$store.dispatch("loadRecipes");
  }

  get recipes() {
    if (!this.tag) {
      return [];
    }

    return this.$store.getters.tag(this.tag);
  }
}
